import React, { useMemo } from 'react';
import clsx from 'classnames';
import Grid from '@material-ui/core/Grid';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { ecgTimeFormat } from 'common/constants/dateFormats';
import { getBeatsRangeHRAvg } from 'common/components/D3Components/utils';
import { getFormattedDuration } from 'common/utils/helpers/date';

import {
  getBeats,
  getVisibleRange,
  getActivityAverage,
  getBodyPositionAverage,
  getTemperatureAverage,
} from 'common/modules/eventStrip/ducks/selectors';
import { eventTriageStripSettings } from 'common/modules/eventStrip/constants';
import PatientEventIcon from 'common/modules/eventStrip/components/PatientEventIcon';
import StripSettings from 'common/modules/eventStrip/components/StripSettings';
import StripValue from 'common/modules/eventStrip/components/StripValue';
import useStyles from 'common/modules/eventStrip/styles';
import Notes from 'common/modules/eventStrip/components/Notes';

const notAvailable = 'N/A';

const StripHeader = ({
  resource = '',
  isModalView = false,
  stripSettings = {},
  selectedEvent = null,
  setStripSettings = () => null,
  selectedBeatRange = null,
  selectedPatientEvent = null,
}) => {
  const styles = useStyles();
  const beats = useSelector(getBeats(resource));
  const visibleRange = useSelector(getVisibleRange(resource));
  const tempAvg = useSelector(getTemperatureAverage(resource));
  const posAvg = useSelector(getBodyPositionAverage(resource));
  const actAvg = useSelector(getActivityAverage(resource));

  const handleUpdateStripSettings = (name, value) => {
    setStripSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const time = useMemo(() => {
    if (selectedEvent?.dateFrom) {
      return moment(selectedEvent.dateFrom).format(ecgTimeFormat);
    }

    if (selectedBeatRange) {
      return moment(selectedBeatRange[0]).format(ecgTimeFormat);
    }

    return notAvailable;
  }, [selectedBeatRange, selectedEvent]);

  const duration = useMemo(() => {
    if (!selectedEvent?.dateFrom || !selectedEvent?.id) {
      return notAvailable;
    }

    return getFormattedDuration(selectedEvent.duration);
  }, [selectedEvent]);

  const stripAverage = useMemo(() => {
    if (!visibleRange || !beats.length) {
      return notAvailable;
    }

    const averageBPM = getBeatsRangeHRAvg(beats, visibleRange);

    return `HR (10s) ${averageBPM}`;
  }, [beats, visibleRange]);

  const eventHRRange = useMemo(() => {
    if (!selectedEvent?.duration || selectedEvent?.duration < 60000) {
      return null;
    }

    const { minHeartRate, maxHeartRate } = selectedEvent;

    return `HR ${minHeartRate} - ${maxHeartRate}`;
  }, [selectedEvent]);

  return (
    <Grid
      container
      spacing={1}
      alignItems="center"
      justifyContent="space-between"
      className={clsx(styles.eventStripHeader, {
        [styles.eventStripHeaderModal]: isModalView,
      })}
    >
      <Grid item container xs={3} wrap="nowrap" alignItems="center" spacing={1}>
        {selectedPatientEvent && <PatientEventIcon />}

        <Notes
          resource={resource}
          event={selectedEvent}
          isBeatSelected={!!selectedBeatRange?.length}
        />
      </Grid>

      <Grid
        item
        container
        xs={6}
        wrap="nowrap"
        alignItems="flex-start"
        justifyContent="space-around"
        style={{ flex: 100, paddingLeft: 16 }}
      >
        <StripValue title="Time:" value={time} flex={17} />
        <StripValue title="Duration:" value={duration} flex={15} />

        <Grid item container direction="column" style={{ flex: 23 }}>
          <StripValue
            title="Strip (10s) Avg:"
            value={stripAverage}
            flex={100}
          />

          {!!eventHRRange && (
            <StripValue
              flex={100}
              title="Event HR Range:"
              value={eventHRRange}
              styles={{ marginTop: 4 }}
            />
          )}
        </Grid>

        <StripValue title="Temp:" value={tempAvg} flex={12} />

        <StripValue title="Activity:" value={actAvg} flex={14} />

        <StripValue title="Position:" value={posAvg} flex={14} />
      </Grid>

      <Grid item container xs alignItems="center">
        {eventTriageStripSettings.map((settingsField) => (
          <StripSettings
            {...settingsField}
            key={settingsField.name}
            currentValues={stripSettings}
            onChange={handleUpdateStripSettings}
          />
        ))}
      </Grid>
    </Grid>
  );
};

StripHeader.propTypes = {
  resource: PropTypes.string,
  isModalView: PropTypes.bool,
  stripSettings: PropTypes.shape(),
  selectedEvent: PropTypes.shape(),
  setStripSettings: PropTypes.func,
  selectedPatientEvent: PropTypes.shape(),
  selectedBeatRange: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
  ),
};

export default StripHeader;
