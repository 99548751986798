import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  eventType,
  containerChildProps,
} from 'common/constants/sharedPropTypes';
import { ecgTypeColors, ecgTypeLabels } from 'common/constants/ecgEnums';

const charWidth = 8;

const D3EventLine = ({ parent, event, ai = false, bottom = 10 }) => {
  const { height, clip, scale } = parent;
  const textRef = useRef(null);

  const yPosition = useMemo(() => height - bottom, [height, bottom]);

  const strokeColor = useMemo(() => {
    const color = ecgTypeColors[event.abnormalityType] || '#ff0000';

    if (ai) {
      return `${color}90`;
    }

    return color;
  }, [ai, event.abnormalityType]);

  const auCheckResult = useMemo(
    () =>
      [
        ecgTypeLabels[event.abnormalityType],
        event.aiResult ? `AI ${event.aiResult}%` : '',
      ].join(' '),
    [event.abnormalityType, event.aiResult]
  );

  const startX = useMemo(
    () => (scale ? scale(new Date(event.dateFrom)) : 0),
    [scale, event.dateFrom]
  );

  const endX = useMemo(
    () => (scale ? scale(new Date(event.dateTo)) : 0),
    [scale, event.dateTo]
  );

  const textStartX = useMemo(() => {
    const textWidth =
      textRef.current?.getBoundingClientRect()?.width ||
      auCheckResult.length * charWidth;

    const centerX = startX + (endX - startX) / 2;

    return centerX - textWidth / 2;
  }, [startX, endX, auCheckResult]);

  return (
    <>
      {ai && (
        <text
          ref={textRef}
          fontSize="15px"
          key={auCheckResult}
          y={yPosition - 10}
          fontWeight="500"
          x={textStartX}
        >
          {auCheckResult}
        </text>
      )}

      <line
        y1={yPosition}
        y2={yPosition}
        strokeWidth={5}
        clipPath={clip}
        x1={startX}
        x2={endX}
        stroke={strokeColor}
      />
    </>
  );
};

D3EventLine.propTypes = {
  ai: PropTypes.bool,
  event: eventType,
  bottom: PropTypes.number,
  parent: containerChildProps,
};

export default D3EventLine;
