import React, {
  lazy,
  memo,
  useMemo,
  useEffect,
  useCallback,
  Suspense,
} from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import Dialog from '@material-ui/core/Dialog';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContactEdit,
  ContactsCreate,
} from 'common/components/Widgets/contacts';
import EventStrip from 'common/modules/eventStrip';
import EventsGrid from 'common/modules/EventsGrid';
import usePrevious from 'common/hooks/usePrevious';
import { modalTypes } from 'common/constants/enums';
import FallbackLoader from 'common/components/FallbackLoader';
import { FileUploadModal } from 'common/components/Widgets/attachments';
import AnalyzeModal from 'common/modules/AiAnalyzer/AnalyzeModal';
import CreatePatient from 'common/modules/patients/components/CreatePatient';
import FullDisclosureModal from 'ui/modules/fullDisclosure';
import PhysicianReportsModal from 'common/modules/reports/components/ReportsModal';
import ReassignKitTenantModal from 'common/modules/kits/components/ReassignKitTenantModal';
import DeleteConfirmation from './components/DeleteConfirmationModal';
import KitAssignModal from './components/KitAssignModal';
import { closeAllModals, closeModal } from './ducks';
import { getModals } from './ducks/selector';
import UpdatePasswordModal from './components/UpdatePasswordModal';
import InformationModal from './components/InformationModal';
import WarningModal from './components/WarningModal';

const PdfReport = lazy(() =>
  import(/* webpackChunkName: "pdfReport" */ 'ui/modules/pdfReport')
);

const PdfPreviewModal = lazy(() =>
  import(
    /* webpackChunkName: "pdfPreviewModal" */ 'ui/modules/pdfReport/PdfPreviewModal'
  )
);

const OpenedModal = (params) => {
  const dispatch = useDispatch();
  const {
    modal,
    modalOptions = {},
    payload: { maxWidth = 'lg', handleClose, ...payload },
  } = params;
  const open = useMemo(() => Boolean(modal), [modal]);

  const onClose = useCallback(
    (e, reason) => {
      if (reason === 'backdropClick') {
        return;
      }

      if (handleClose) {
        handleClose();
      }

      dispatch(closeModal(modal));
    },
    [handleClose, dispatch, modal]
  );

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      fullWidth={!!maxWidth}
      onClose={onClose}
      maxWidth={maxWidth}
      {...modalOptions}
    >
      {
        {
          [modalTypes.eventStripModal]: (
            <EventStrip isTriageView onClose={onClose} {...payload} />
          ),
          [modalTypes.similarEventsGridModal]: (
            <EventsGrid onClose={onClose} {...payload} modal />
          ),
          [modalTypes.fullDisclosureModal]: (
            <FullDisclosureModal onClose={onClose} {...payload} />
          ),
          [modalTypes.fileUploadModal]: (
            <FileUploadModal onClose={onClose} {...payload} />
          ),
          [modalTypes.kitAssignModal]: (
            <KitAssignModal onClose={onClose} {...payload} />
          ),
          [modalTypes.contactCreate]: (
            <ContactsCreate onClose={onClose} {...payload} />
          ),
          [modalTypes.contactEdit]: (
            <ContactEdit onClose={onClose} {...payload} />
          ),
          [modalTypes.physicianReportsReview]: (
            <PhysicianReportsModal onClose={onClose} {...payload} />
          ),
          [modalTypes.pdfReport]: (
            <Suspense fallback={<FallbackLoader />}>
              <PdfReport onClose={onClose} {...payload} />
            </Suspense>
          ),

          [modalTypes.confirmation]: (
            <DeleteConfirmation onClose={onClose} {...payload} />
          ),
          [modalTypes.pdfPreview]: (
            <Suspense fallback={<FallbackLoader />}>
              <PdfPreviewModal onClose={onClose} {...payload} />
            </Suspense>
          ),
          [modalTypes.addNewPatient]: (
            <CreatePatient onClose={onClose} {...payload} />
          ),
          [modalTypes.reassignKitTenantModal]: (
            <ReassignKitTenantModal onClose={onClose} {...payload} />
          ),
          [modalTypes.updatePasswordModal]: (
            <UpdatePasswordModal onClose={onClose} {...payload} />
          ),
          [modalTypes.informationModal]: (
            <InformationModal onClose={onClose} {...payload} />
          ),
          [modalTypes.warningModal]: (
            <WarningModal onClose={onClose} {...payload} />
          ),
          [modalTypes.aiAnalyze]: (
            <AnalyzeModal onClose={onClose} {...payload} />
          ),
        }[modal]
      }
    </Dialog>
  );
};

const areEqual = (prev, next) =>
  prev.modal === next.modal && isEqual(prev.payload, next.payload);
const Modal = memo(OpenedModal, areEqual);

OpenedModal.propTypes = {
  modal: PropTypes.string.isRequired,
  payload: PropTypes.objectOf(PropTypes.any),
};

const ModalHandler = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const openedModals = useSelector(getModals);

  const prevPathname = usePrevious(location.pathname);

  const handleCloseModalOnRedirect = useCallback(() => {
    if (!openedModals?.length) {
      return;
    }

    if (prevPathname && prevPathname !== location.pathname) {
      dispatch(closeAllModals());
    }
  }, [dispatch, location.pathname, prevPathname, openedModals]);

  useEffect(() => () => dispatch(closeAllModals()), [dispatch]);

  useEffect(() => {
    handleCloseModalOnRedirect();
  }, [handleCloseModalOnRedirect]);

  if (!openedModals.length) {
    return null;
  }

  return (
    <Suspense fallback="loading">
      {openedModals.map((modalData) => (
        <Modal key={modalData.modal} {...modalData} />
      ))}
    </Suspense>
  );
};

export default ModalHandler;
