import { aiModelTypes } from '../constants/enums';

const veAI = (xData) => {
  const min = Math.min(...xData);
  const max = Math.max(...xData);
  return xData.map((x) => (x - min) / (max - min));
};

function veAIA(
  xData,
  lowerExtreme = 1100,
  upperExtreme = 3600,
  targetMin = 0.1,
  targetMax = 0.9
) {
  const eps = Number.EPSILON;

  const xDataFiltered = xData.map((value) =>
    value < lowerExtreme || value > upperExtreme ? 0.0 : value
  );

  const xMin = Math.min(
    ...xDataFiltered.filter((value) => value > 0),
    Number.MAX_VALUE
  );
  const xMax = Math.max(
    ...xDataFiltered.filter((value) => value > 0),
    -Number.MAX_VALUE
  );

  const dataRange = xMax - xMin + eps;

  const xDataNorm = xDataFiltered.map((value) =>
    value > 0 ? (value - xMin) / dataRange : 0.0
  );

  const scaleRange = targetMax - targetMin;
  const xDataScaled = xDataNorm.map((value) => value * scaleRange + targetMin);

  return xDataFiltered.map((value, i) => (value === 0 ? 0 : xDataScaled[i]));
}

const veNormalizers = {
  [aiModelTypes.AI]: veAI,
  [aiModelTypes.AIA]: veAIA,
};

export default veNormalizers;
