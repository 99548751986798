import { modalTypes } from 'common/constants/enums';
import { modalsActionTypes } from 'common/ducks/actionTypes';

export const openEventStripModal = (payload) => ({
  type: modalsActionTypes.open.requested,
  modal: modalTypes.eventStripModal,
  payload,
});

export const openSimilarEventsGridModal = (payload) => ({
  type: modalsActionTypes.open.requested,
  modal: modalTypes.similarEventsGridModal,
  payload,
});

export const openFullDisclosureModal = (payload = {}) => ({
  type: modalsActionTypes.open.requested,
  modal: modalTypes.fullDisclosureModal,
  payload: { ...payload, maxWidth: 'xl' },
});

export const openFileUploadModal = (payload = {}) => ({
  type: modalsActionTypes.open.requested,
  modal: modalTypes.fileUploadModal,
  payload,
});

export const openKitAssignModal = (payload = {}) => ({
  type: modalsActionTypes.open.requested,
  modal: modalTypes.kitAssignModal,
  payload,
});

export const openReassignKitTenantModal = (payload = {}) => ({
  type: modalsActionTypes.open.requested,
  modal: modalTypes.reassignKitTenantModal,
  payload: { ...payload, maxWidth: 'xs' },
});

export const openContactCreateModal = (payload = {}) => ({
  type: modalsActionTypes.open.requested,
  modal: modalTypes.contactCreate,
  payload,
});

export const openContactEditModal = (payload = {}) => ({
  type: modalsActionTypes.open.requested,
  modal: modalTypes.contactEdit,
  payload,
});

export const openPhysicianReportsReview = (payload = {}) => ({
  type: modalsActionTypes.open.requested,
  modal: modalTypes.physicianReportsReview,
  payload,
});

export const openPdfReportModal = (payload = {}) => ({
  type: modalsActionTypes.open.requested,
  modal: modalTypes.pdfReport,
  payload: { ...payload, maxWidth: 'md' },
});

export const openConfirmationModal = (payload = {}) => ({
  type: modalsActionTypes.open.requested,
  modal: modalTypes.confirmation,
  payload: { maxWidth: 'sm', ...payload },
});

export const openPdfPreview = (payload = {}) => ({
  type: modalsActionTypes.open.requested,
  modal: modalTypes.pdfPreview,
  payload: { ...payload, maxWidth: 'md' },
});

export const openAddNewPatient = (payload = {}) => ({
  type: modalsActionTypes.open.requested,
  modal: modalTypes.addNewPatient,
  payload: { ...payload, maxWidth: 'md' },
});

export const openUpdatePassword = (payload = {}) => ({
  type: modalsActionTypes.open.requested,
  modal: modalTypes.updatePasswordModal,
  payload: { ...payload, maxWidth: 'sm' },
});

export const openInformationModal = (payload = {}) => ({
  type: modalsActionTypes.open.requested,
  modal: modalTypes.informationModal,
  payload: { ...payload, maxWidth: 'sm' },
});

export const openWarningModal = (payload = {}) => ({
  type: modalsActionTypes.open.requested,
  modal: modalTypes.warningModal,
  payload: { ...payload, maxWidth: false },
});

export const openAiAnalyze = (payload = {}) => ({
  type: modalsActionTypes.open.requested,
  modal: modalTypes.aiAnalyze,
  payload,
});

export const closeModal = (modal) => ({
  type: modalsActionTypes.close.requested,
  modal,
});

export const closeAllModals = () => ({
  type: modalsActionTypes.closeAll.requested,
});
