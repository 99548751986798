import moment from 'moment';

import {
  aiModelTypes,
  eventStartEdges,
  eventAnalyzeEcgLength,
} from '../constants/enums';
import { getFirstEqualOrAfterTimeIndex } from '../../../utils/helpers/date';

export const getEventAnalyzeECGRange = (event, modelType = aiModelTypes.AI) => {
  const { abnormalityType, dateFrom } = event;

  const [start, end] = eventStartEdges[abnormalityType][modelType] || [1, 1];

  return {
    dateFrom: moment(dateFrom).subtract(start, 'second').toISOString(),
    dateTo: moment(dateFrom).add(end, 'seconds').toISOString(),
  };
};

export const getEcgDataWithCorrectLength = (
  event,
  ecg,
  modelType = aiModelTypes.AI
) => {
  const { abnormalityType } = event;

  const lengthToAnalyze =
    eventAnalyzeEcgLength[abnormalityType][modelType] || 200;

  let ecgData = ecg.map(({ value }) => value);

  if (ecgData.length > lengthToAnalyze) {
    ecgData = ecgData.slice(0, lengthToAnalyze);
  }

  if (ecgData.length < lengthToAnalyze) {
    const diff = lengthToAnalyze - ecgData.length;
    const mockData = new Array(diff).fill(0);
    ecgData = [ecgData, mockData].flat();
  }

  return ecgData;
};

export const findIndexPrevOrNextEvent = (time, events) => {
  if (events?.length <= 1) {
    return {};
  }

  const sortedEvents = events.sort(
    (a, b) => Number(new Date(a.dateFrom)) - Number(new Date(b.dateFrom))
  );

  const index = getFirstEqualOrAfterTimeIndex(sortedEvents, time, 'dateFrom');

  const prevIndex = index < 0 ? index : index - 1;
  const nextIndex = index < 0 ? index : index + 1;

  const prevEvent = events[prevIndex];
  const nextEvent = events[nextIndex];

  return { prevEvent, nextEvent };
};
