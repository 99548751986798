import {
  templateTypes,
  abnormalityTypeEnum,
  escalatedTriageAbnormalities,
} from 'common/constants/ecgEnums';
import { ecgEventStatus } from 'common/constants/enums';

export const commonTriages = [
  abnormalityTypeEnum.ve,
  abnormalityTypeEnum.veTemplates,
  abnormalityTypeEnum.vPair,
  abnormalityTypeEnum.veBigeminy,
  abnormalityTypeEnum.veTrigeminy,
  abnormalityTypeEnum.sve,
  abnormalityTypeEnum.sveTemplates,
  abnormalityTypeEnum.svPair,
  abnormalityTypeEnum.sveBigeminy,
  abnormalityTypeEnum.sveTrigeminy,
];
export const escalatedTriages = escalatedTriageAbnormalities;

export const requestWithoutAbnormality = templateTypes;

export const triageButtonTypes = {
  acceptedIncluded: 1,
  declined: 2,
  escalatedFirstTab: 3,
  escalatedSecondTab: 4,
  aiDeclined: 5,
};

const firstTabEscalated = [
  abnormalityTypeEnum.afib,
  abnormalityTypeEnum.pause,
  abnormalityTypeEnum.vTach,
  abnormalityTypeEnum.svTach,
  abnormalityTypeEnum.vRun,
];
const secondTabEscalated = [
  abnormalityTypeEnum.svRun,
  abnormalityTypeEnum.tachy,
  abnormalityTypeEnum.brady,
  abnormalityTypeEnum.block,
  abnormalityTypeEnum.patient,
];

export const eventTabs = {
  [triageButtonTypes.acceptedIncluded]: {
    getEvents: (common) => (common ? commonTriages : escalatedTriages),
    status: [ecgEventStatus.accepted, ecgEventStatus.included],
    reviewed: true,
  },
  [triageButtonTypes.declined]: {
    getEvents: (common) => (common ? commonTriages : escalatedTriages),
    status: [ecgEventStatus.denied],
    reviewed: true,
    hasAIStatus: false,
  },
  [triageButtonTypes.aiDeclined]: {
    getEvents: (common) =>
      common
        ? commonTriages
        : [abnormalityTypeEnum.afib, abnormalityTypeEnum.pause],
    status: [ecgEventStatus.denied],
    reviewed: true,
    hasAIStatus: true,
  },
  [triageButtonTypes.escalatedFirstTab]: {
    getEvents: () => firstTabEscalated,
    events: firstTabEscalated,
    reviewed: false,
  },
  [triageButtonTypes.escalatedSecondTab]: {
    reviewed: false,
    getEvents: () => secondTabEscalated,
    events: secondTabEscalated,
  },
};

export const visibleEventsForEscalatedTriage = [
  ...firstTabEscalated,
  ...secondTabEscalated,
];

export const escalatedRowHeight = 120;

export const gridProps = {
  perPage: 240,
  graphProps: { rectangleStrokeWidth: 0 },
  columnsAmount: 6,
  nestedSelection: 'similar',
  serverPagination: true,
};
