import { abnormalityTypeEnum } from 'common/constants/ecgEnums';

import { aiModelTypes } from './enums';

// ai
const vPairAIModel = import('assets/aiModels/8-model-vPairAI.onnx');
const veBigeminyAiModel = import('assets/aiModels/9-model-veBigeminyAi.onnx');
const veTrigeminyAiModel = import(
  'assets/aiModels/10-model-veTrigeminyAI.onnx'
);
const veAIModel = import('assets/aiModels/11-model-veAI.onnx');
const svPairAIModel = import('assets/aiModels/13-model-svPairAI.onnx');
const sveBigeminyAIModel = import(
  'assets/aiModels/14-model-sveBigeminyAI.onnx'
);
const sveTrigeminyAIModel = import(
  'assets/aiModels/15-model-sveTrigeminyAI.onnx'
);
const sveAIModel = import('assets/aiModels/16-model-sveAI.onnx');
const sveAIAModel = import('assets/aiModels/16-model-sveAIA.onnx');

// AIA models
const veAIA = import('assets/aiModels/11-model-veAIA.onnx');

// beats
const heartRates = import('assets/aiModels/HeartRates.onnx');

export const modelsAccordingEventType = {
  // 8
  [abnormalityTypeEnum.vPair]: {
    [aiModelTypes.AI]: vPairAIModel,
    [aiModelTypes.AIA]: null,
  },
  // 9
  [abnormalityTypeEnum.veBigeminy]: {
    [aiModelTypes.AI]: veBigeminyAiModel,
    [aiModelTypes.AIA]: null,
  },
  // 10
  [abnormalityTypeEnum.veTrigeminy]: {
    [aiModelTypes.AI]: veTrigeminyAiModel,
    [aiModelTypes.AIA]: null,
  },
  // 11
  [abnormalityTypeEnum.ve]: {
    [aiModelTypes.AI]: veAIModel,
    [aiModelTypes.AIA]: veAIA,
  },
  // 12
  // ====
  // 13
  [abnormalityTypeEnum.svPair]: {
    [aiModelTypes.AI]: svPairAIModel,
    [aiModelTypes.AIA]: null,
  },
  // 14
  [abnormalityTypeEnum.sveBigeminy]: {
    [aiModelTypes.AI]: sveBigeminyAIModel,
    [aiModelTypes.AIA]: null,
  },
  // 15
  [abnormalityTypeEnum.sveTrigeminy]: {
    [aiModelTypes.AI]: sveTrigeminyAIModel,
    [aiModelTypes.AIA]: null,
  },
  // 16
  [abnormalityTypeEnum.sve]: {
    [aiModelTypes.AI]: sveAIModel,
    [aiModelTypes.AIA]: sveAIAModel,
  },

  // Beats Analyzer model
  [abnormalityTypeEnum.beats]: {
    [aiModelTypes.AI]: heartRates,
    [aiModelTypes.AIA]: heartRates,
  },
};
