import { v4 as uuidV4 } from 'uuid';
import uniqBy from 'lodash/uniqBy';

import { getFirstEqualOrAfterTimeIndex } from 'common/utils/helpers/date';

import {
  aiModelTypes,
  eventAiaCheckEdge,
  eventAnalyzeEcgLength,
} from '../constants/enums';
import {
  getEventAnalyzeECGRange,
  getEcgDataWithCorrectLength,
} from './getters';
import { aiaEventAnalyzer, aiEventAnalyzer } from './aiEventAnalyzer';
import {
  abnormalityTypeEnum,
  ecgTypeLabels,
} from '../../../constants/ecgEnums';

export const getEcgPartsForAIA = (ecg, abType) => {
  const clearEcg = uniqBy(ecg, 'time');
  const maxLength = eventAnalyzeEcgLength[abType][aiModelTypes.AIA];
  const maxParts = Math.ceil(clearEcg.length / maxLength);

  return new Array(maxParts).fill(null).map((_, index) => {
    const start = index * maxLength;
    const end = start + maxLength;

    let ecgData = clearEcg.slice(
      !index ? start : start + 10,
      !index ? end : end + 10
    );

    if (ecgData.length < maxLength) {
      const diff = maxLength - ecgData.length;
      const mockData = new Array(diff).fill(0);
      ecgData = [ecgData, mockData].flat();
    }

    return ecgData;
  });
};

const timeWindow = 0.5;

export function postProcess(
  analyzedStream,
  threshold,
  eventLength,
  step = 0.016
) {
  const outputs = [...analyzedStream];
  let lastEventEndIndex = 0;
  let lastEventStartIndex = 0;

  for (let i = 0; i < outputs.length - 1; i += 1) {
    const output = outputs[i];
    const nextOutput = outputs[i + 1];

    if (output >= threshold && nextOutput < threshold) {
      lastEventEndIndex = i;
    }

    if (output >= threshold && lastEventStartIndex === 0) {
      lastEventStartIndex = i;
    }

    if (output < threshold && lastEventStartIndex > 0) {
      if ((i - lastEventStartIndex) * step < eventLength / 2) {
        for (let j = lastEventStartIndex; j <= i; j += 1) {
          outputs[j] = 0;
        }
      } else {
        lastEventStartIndex = 0;
      }
    }

    if (
      output < threshold &&
      nextOutput >= threshold &&
      lastEventEndIndex > 0
    ) {
      if ((i - lastEventEndIndex) * step < timeWindow) {
        for (let j = lastEventEndIndex; j <= i; j += 1) {
          outputs[j] = 1;
        }
      } else {
        lastEventEndIndex = 0;
      }
    }
  }

  return outputs;
}

const defaultNormalizeOptions = {
  lowerExtreme: 1100,
  upperExtreme: 3600,
};

const beatsNormalizeOptions = {
  lowerExtreme: 1200,
  upperExtreme: 3600,
};

export const analyzeEcgStrip = async (
  ecgPart,
  abType,
  edge = 0,
  normalizeOptions = defaultNormalizeOptions
) => {
  const acceptanceEdge = edge || eventAiaCheckEdge[abType];

  const partToAnalyze = ecgPart.map((d) =>
    typeof d === 'number' ? d : d.value || 0
  );

  const aiAnalyzeResult = await aiaEventAnalyzer({
    ecg: partToAnalyze,
    abnormalityType: abType,
    originalEcg: ecgPart,
    acceptanceThreshold: acceptanceEdge,
    normalizeOptions:
      abType === abnormalityTypeEnum.beats
        ? beatsNormalizeOptions
        : normalizeOptions,
  });

  return [...aiAnalyzeResult.data];
};

export const searchEcgEvents = (analyzedStream, abType, edge) => {
  let event = null;
  const events = [];
  const acceptanceEdge = edge || eventAiaCheckEdge[abType];

  analyzedStream.forEach((ePart, index) => {
    if (!ePart || ePart <= acceptanceEdge) {
      return;
    }

    if (!event) {
      event = {
        id: uuidV4(),
        aiLabel: `${ecgTypeLabels[abType]} - AIA`,
        abnormalityType: abType,
        dateFrom: ePart.time,
        dateTo: ePart.time,
      };
    }

    event.dateTo = ePart.time;

    const nextPart = analyzedStream[index + 1];

    if (!nextPart) {
      events.push(event);
      event = null;
    }
  });

  return events;
};

export const checkEvents = (events, ecg) => {
  const eventsCheckPromiseList = events.map(async (event) => {
    const { dateFrom, dateTo } = getEventAnalyzeECGRange(
      event,
      aiModelTypes.AI
    );

    const start = getFirstEqualOrAfterTimeIndex(ecg, dateFrom);
    const end = getFirstEqualOrAfterTimeIndex(ecg, dateTo) || ecg.length - 1;
    const ecgToAnalyze = getEcgDataWithCorrectLength(
      event,
      ecg.slice(start, end),
      aiModelTypes.AI
    );

    const output = await aiEventAnalyzer({
      ecg: ecgToAnalyze,
      abnormalityType: event.abnormalityType,
    });

    const aiResult = Math.ceil((output?.data?.[0] || 0) * 100);

    return { ...event, aiResult };
  });

  return Promise.all(eventsCheckPromiseList);
};
