import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  loaderText: {
    marginTop: 50,
    paddingLeft: 10,
    userSelect: 'none',
    textAlign: 'center',
    animation: '$pulse 2.5s linear infinite',
  },

  '@keyframes pulse': {
    '0%': { opacity: 0.1 },
    '50%': { opacity: 1 },
    '100%': { opacity: 0.1 },
  },
});

const LoaderOverlay = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 2,
        backgroundColor: 'rgba(0,0,0,.2)',
      }}
    >
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress style={{ width: 110, height: 110 }} />

        <Typography variant="h2" className={classes.loaderText}>
          Analysis...
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LoaderOverlay;
