import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';

import { ecgTypeLabels } from 'common/constants/ecgEnums';
import { eventGroupType } from 'common/constants/sharedPropTypes';
import NoteInput from './NoteInput';
import CustomPeriodButtons from './CustomPeriodButtons';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  container: {
    padding: spacing(0, 1),
    width: 'calc(100% - 70px)',
  },
  noteName: {
    fontSize: '1rem',
    [breakpoints.down(1367)]: {
      fontSize: '0.7rem',
    },
  },
}));

const NotesContainer = ({
  event,
  resource,
  customNote,
  openedEvent,
  isBeatSelected,
  onChangeNote = () => null,
}) => {
  const classes = useStyles();

  const naString = useMemo(
    () => (isBeatSelected ? 'Sinus rhythm' : 'N/A'),
    [isBeatSelected]
  );

  const abnormalityLabel = useMemo(() => {
    if (typeof event?.aiResult === 'number' && event?.abnormalityType) {
      const aiPercents = Math.ceil(event.aiResult * 100);

      return `${ecgTypeLabels[event.abnormalityType]} (AI ${aiPercents}%)`;
    }

    if (event?.abnormalityType) {
      return ecgTypeLabels[event.abnormalityType] || naString;
    }

    return naString;
  }, [naString, event]);

  return (
    <Grid item container direction="column">
      <Typography
        item
        variant="h4"
        component={Grid}
        className={classes.noteName}
      >
        {abnormalityLabel}
      </Typography>

      {customNote && (
        <>
          <Grid item>
            <NoteInput value={event?.note} onChange={onChangeNote} />
          </Grid>

          <Grid item>
            <CustomPeriodButtons
              resource={resource}
              event={event}
              openedEvent={openedEvent}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

NotesContainer.propTypes = {
  event: PropTypes.oneOfType([eventGroupType, PropTypes.any]),
  customNote: PropTypes.bool,
  openedEvent: PropTypes.oneOfType([eventGroupType, PropTypes.any]),
  isBeatSelected: PropTypes.bool,
  onChangeNote: PropTypes.func,
  resource: PropTypes.string,
};

const isEqualProps = (p, n) =>
  p.event === n.event && p.isBeatSelected === n.isBeatSelected;

export default React.memo(NotesContainer, isEqualProps);
