import wasm from 'assets/wasm/ort-wasm.wasm';
import wasmSimd from 'assets/wasm/ort-wasm-simd.wasm';
import wasmThreaded from 'assets/wasm/ort-wasm-threaded.wasm';
import wasmSimdThreaded from 'assets/wasm/ort-wasm-simd-threaded.wasm';
import { abnormalityTypeEnum } from 'common/constants/ecgEnums';
import createEnum from 'common/utils/actionTypesHelper/createEnum';

export const aiModelTypes = createEnum({
  // analyze event probability
  AI: 'ai',
  // analyze event strip to find events
  AIA: 'aia',
});

export const eventStartEdges = createEnum({
  [abnormalityTypeEnum.ve]: {
    [aiModelTypes.AI]: [1, 2],
    [aiModelTypes.AIA]: [1, 4],
  },

  [abnormalityTypeEnum.sve]: {
    [aiModelTypes.AI]: [1, 4],
    [aiModelTypes.AIA]: [1, 4],
  },

  [abnormalityTypeEnum.beats]: {
    [aiModelTypes.AI]: [1, 4],
    [aiModelTypes.AIA]: [1, 4],
  },
});

export const eventLength = createEnum({
  [abnormalityTypeEnum.ve]: 0.5,
  [abnormalityTypeEnum.sve]: 0.5,
});

export const eventAnalyzeEcgLength = createEnum({
  [abnormalityTypeEnum.ve]: {
    [aiModelTypes.AI]: 187,
    [aiModelTypes.AIA]: 312,
  },

  [abnormalityTypeEnum.sve]: {
    [aiModelTypes.AI]: 187,
    [aiModelTypes.AIA]: 312,
  },

  // beats analyzer
  [abnormalityTypeEnum.beats]: {
    [aiModelTypes.AI]: 312,
    [aiModelTypes.AIA]: 312,
  },
});

export const eventAiaCheckEdge = {
  [abnormalityTypeEnum.ve]: 0.5,
  [abnormalityTypeEnum.sve]: 0.5,
  [abnormalityTypeEnum.beats]: 0.9,
};

export const wasmPaths = createEnum({
  'ort-wasm.wasm': wasm,
  'ort-wasm-simd.wasm': wasmSimd,
  'ort-wasm-threaded.wasm': wasmThreaded,
  'ort-wasm-simd-threaded.wasm': wasmSimdThreaded,
});
