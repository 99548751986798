import React, { useCallback, useMemo } from 'react';
import clsx from 'classnames';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialTextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import useStyles from './styles';

const sanitizeProps = ({
  onInputChange,
  optionLabel,
  optionValueName,
  getValue,
  multipleSelect,
  ...props
}) => props;

const Input = ({
  name,
  select,
  options,
  label = '',
  classes,
  className = '',
  variant = 'outlined',
  leftIcon,
  InputProps = {},
  field = {},
  value = '',
  optionLabel = 'label',
  optionValueName = 'value',
  multiple,
  onClose,
  SelectProps,
  ...rest
}) => {
  const props = sanitizeProps(rest);
  const isSelect = Boolean(select && options);

  const onChange = useCallback(
    (event) => {
      if (field.onChange) {
        field.onChange(event, event.target.value);
      }

      if (props.onChange) {
        props.onChange(event, event.target.value);
      }
    },
    [field, props]
  );

  const currentValue = useMemo(() => {
    const v = value || field.value;
    const resultValue = v || typeof v === 'number' ? v : '';
    if (multiple && !Array.isArray(resultValue)) {
      return resultValue ? [resultValue] : [];
    }
    return resultValue;
  }, [value, field.value, multiple]);

  const styles = useStyles();

  const selectProps = useMemo(() => {
    if (!multiple) {
      return SelectProps;
    }

    return {
      onClose: (e) => {
        if (onClose) {
          onClose(e, field.onChange ? field : { onChange, value });
        }
      },
      multiple: true,
      renderValue: (selected) => (
        <div className={styles.chips}>
          {selected.map((item) => {
            const option = (options || []).find(
              (opt) => opt[optionValueName] === item
            );
            const currentLabel = option?.[optionLabel];
            return (
              <Chip key={item} label={currentLabel} className={styles.chip} />
            );
          })}
        </div>
      ),
      ...(SelectProps || {}),
    };
  }, [
    field,
    value,
    styles,
    onClose,
    options,
    onChange,
    multiple,
    optionLabel,
    SelectProps,
    optionValueName,
  ]);

  return (
    <MaterialTextField
      name={name}
      title={label}
      {...props}
      {...field}
      label={label}
      field={field}
      variant={variant}
      select={isSelect}
      classes={classes}
      onChange={onChange}
      value={currentValue}
      SelectProps={selectProps}
      className={clsx(className, styles.root)}
      InputProps={{
        startAdornment: leftIcon && (
          <InputAdornment position="start">{leftIcon}</InputAdornment>
        ),
        ...InputProps,
      }}
    >
      {isSelect &&
        options.map((option) => (
          <MenuItem key={option[optionLabel]} value={option[optionValueName]}>
            {option[optionLabel]}
          </MenuItem>
        ))}
    </MaterialTextField>
  );
};

Input.propTypes = {
  select: PropTypes.bool,
  label: PropTypes.string,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  variant: PropTypes.string,
  className: PropTypes.string,
  onInputChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  field: PropTypes.objectOf(PropTypes.any),
  classes: PropTypes.shape(),
  options: PropTypes.arrayOf(PropTypes.object),
  InputProps: PropTypes.objectOf(PropTypes.any),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
  leftIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  optionLabel: PropTypes.string,
  optionValueName: PropTypes.string,
  multiple: PropTypes.bool,
  SelectProps: PropTypes.shape(),
};

export default Input;
