import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import * as d3 from 'd3';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import groupBy from 'lodash/groupBy';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';

import {
  getEcg,
  getYMinMaxValues,
} from 'common/modules/eventStrip/ducks/selectors';
import { abnormalityTypeEnum } from 'common/constants/ecgEnums';
import { eventTriageStripSettings } from 'common/modules/eventStrip/constants';
import { getRecordsOverlappedByRange } from 'common/utils/helpers/date';
import Button from 'common/components/buttons/ModalActionButton';

import Header from './Header';
import EventsNav from './EventsNav';
import LoaderOverlay from './LoaderOverlay';
import EventStripGraph from './EventStripGraph';
import {
  postProcess,
  searchEcgEvents,
  analyzeEcgStrip,
  getEcgPartsForAIA,
} from '../utils/ecgUtils';
import TextInput from '../../../components/simpleInputs/TextInput';
import useNotify from '../../Notification/hooks/useNotify';
import { eventAiaCheckEdge, eventLength } from '../constants/enums';

const eventTypesToAnalyze = [
  abnormalityTypeEnum.ve,
  abnormalityTypeEnum.sve,
  abnormalityTypeEnum.beats,
];

const defaultNormalizeOptions = {
  lowerExtreme: 1100,
  upperExtreme: 3600,
};

const defaultStripSettings = eventTriageStripSettings.reduce(
  (acc, val) => ({
    ...acc,
    [val.name]: val.defaultValue,
  }),
  {}
);

const AnalyzeModal = ({ resource, center, onClose }) => {
  const notify = useNotify();
  const graphContainer = useRef(null);
  const [aiEvents, setAiEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [maxWidth, setMaxWidth] = useState(700);
  const [visibleRange, setVisibleRange] = useState(null);
  const [analyzedBeats, setAnalyzedBeats] = useState([]);
  const [stripSettings, setStripSettings] = useState(defaultStripSettings);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedBeatRange, setSelectedBeatRange] = useState(null);
  const [acceptanceEdges, setAcceptanceEdge] = useState(eventAiaCheckEdge);
  const [currentCenter, setCenter] = useState(center);
  const [normalizeOptions, setNormalize] = useState(defaultNormalizeOptions);

  const ecg = useSelector(getEcg(resource));
  const { min: yMin, max: yMax } = useSelector(getYMinMaxValues(resource));

  const xDomain = useMemo(() => d3.extent(ecg, (d) => new Date(d.time)), [ecg]);

  const yDomain = useMemo(() => [yMin - 225, yMax + 225], [yMin, yMax]);

  const focusOptions = useMemo(() => {
    return {
      center: currentCenter || center,
      domain: {
        x: xDomain,
        y: yDomain,
      },
      duration: 10,
    };
  }, [center, xDomain, yDomain, currentCenter]);

  const visibleEvents = useMemo(
    () =>
      getRecordsOverlappedByRange(
        aiEvents,
        [visibleRange].flat().filter(Boolean)
      ),
    [aiEvents, visibleRange]
  );

  const startAnalyze = useCallback(
    async ({ edges, normalizeOpts }) => {
      setLoading(true);
      setAiEvents([]);

      if (!ecg.length) {
        setLoading(false);
        return;
      }

      try {
        const allTypesChecks = eventTypesToAnalyze.map(async (abType) => {
          const parts = getEcgPartsForAIA(ecg, abType);

          const edge = +(edges || eventAiaCheckEdge)[abType];

          const searchRun = parts.map((ecgPart) =>
            analyzeEcgStrip(ecgPart, abType, edge, normalizeOpts)
          );

          const analyzedStream = (await Promise.all(searchRun)).flat();

          const fullEcg = parts.flat();

          const dataToSearch =
            abType === abnormalityTypeEnum.beats
              ? analyzedStream
              : postProcess(analyzedStream, edge, eventLength[abType]);

          const modifiedData = dataToSearch.map((r, index) =>
            r > edge ? fullEcg[index] || 0 : 0
          );

          if (abType === abnormalityTypeEnum.beats) {
            const aiaBeats = [modifiedData].flat().filter(Boolean);
            setAnalyzedBeats(aiaBeats);

            return;
          }

          const events = searchEcgEvents(modifiedData, abType, edge);

          setAiEvents((prevEvents) => [...prevEvents, ...events]);
        });

        await Promise.all(allTypesChecks);
      } finally {
        setLoading(false);
      }
    },
    [ecg]
  );

  const handleChangeThreshold = (e) => {
    const val = String(e.target.value).replace(/[^0-9.]/g, '');

    setAcceptanceEdge((v) => ({ ...v, [e.target.name]: val }));
  };

  const handleChangeExtreme = (e) => {
    const val = String(e.target.value).replace(/[^0-9]/g, '');

    setNormalize((v) => ({ ...v, [e.target.name]: val }));
  };

  const graphClickHandler = useCallback((bRange) => {
    setSelectedBeatRange(bRange);
    setSelectedEvent(null);
  }, []);

  const groupedEvents = useMemo(
    () => groupBy(aiEvents, 'abnormalityType'),
    [aiEvents]
  );

  const countLabel = useMemo(() => {
    const eventKeys = Object.keys(groupedEvents);

    if (!eventKeys.length) {
      return '0';
    }

    return eventKeys
      .map((abT) => `${abT} - ${groupedEvents[abT].length}`)
      .join(', ');
  }, [groupedEvents]);

  const handleManualAnalyze = (visibleOnly = false) => {
    const { lowerExtreme, upperExtreme } = normalizeOptions;

    if (
      !lowerExtreme ||
      !upperExtreme ||
      Number.isNaN(lowerExtreme) ||
      Number.isNaN(upperExtreme)
    ) {
      notify.error(
        'Please set correct Lower or Upper Extreme value. The value must be a number and not empty'
      );

      return null;
    }

    const notValid = Object.keys(acceptanceEdges).filter((k) => {
      const edge = +acceptanceEdges[k];

      return !edge || edge < 0 || edge > 1;
    });

    if (notValid.length) {
      const notValidValues = notValid.join(', ');

      notify.error(
        `Please set correct Acceptance Threshold value for ${notValidValues}. The value must be in range [0, 1]`
      );
      return null;
    }

    return startAnalyze({
      visibleOnly,
      edges: acceptanceEdges,
      normalizeOpts: normalizeOptions,
    });
  };

  useEffect(() => {
    if (!graphContainer.current) {
      return;
    }

    setMaxWidth(graphContainer.current.clientWidth - 32);
  }, [graphContainer]);

  useEffect(() => {
    startAnalyze({});
  }, [startAnalyze]);

  return (
    <Card style={{ flex: 1, position: 'relative' }}>
      {loading && <LoaderOverlay />}

      <Header
        isModalView
        resource={resource}
        selectedEvent={selectedEvent}
        stripSettings={stripSettings}
        setStripSettings={setStripSettings}
      />

      <Grid
        item
        container
        direction="column"
        ref={graphContainer}
        style={{ position: 'relative', padding: '8px 16px' }}
      >
        <EventStripGraph
          height={350}
          width={maxWidth}
          onClick={graphClickHandler}
          settings={stripSettings}
          resource={resource}
          aiEvents={visibleEvents}
          selectedEvent={selectedEvent}
          position={selectedBeatRange}
          visibleRange={visibleRange}
          focusOptions={focusOptions}
          analyzedBeats={analyzedBeats}
          setVisibleRange={setVisibleRange}
          handleEventSelect={setSelectedEvent}
        />
      </Grid>

      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        style={{ padding: '8px 16px' }}
      >
        <Grid
          item
          container
          direction="column"
          alignItems="flex-end"
          style={{ paddingBottom: '8px', gap: 24 }}
        >
          <Grid item container style={{ gap: 10 }}>
            <Grid item container style={{ gap: 10 }}>
              <TextInput
                name="lowerExtreme"
                size="small"
                label="Lower Extreme"
                value={normalizeOptions.lowerExtreme}
                onChange={handleChangeExtreme}
              />

              <TextInput
                name="upperExtreme"
                size="small"
                label="Upper Extreme"
                value={normalizeOptions.upperExtreme}
                onChange={handleChangeExtreme}
              />
            </Grid>

            <Typography>
              Lower and Upper extremes to normalize ECG for AIA
            </Typography>
          </Grid>

          <Grid item container style={{ gap: 10 }}>
            <Grid container style={{ gap: 10 }}>
              {eventTypesToAnalyze.map((name) => {
                return (
                  <Grid item key={name}>
                    <TextInput
                      name={name}
                      size="small"
                      label={`${name} Threshold (${eventAiaCheckEdge[name]})`}
                      value={acceptanceEdges[name]}
                      onChange={handleChangeThreshold}
                    />
                  </Grid>
                );
              })}
            </Grid>

            <Typography>
              Select AIA Acceptance Threshold between 0.1 and 1
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction="row"
          alignItems="center"
          xs={6}
          style={{ marginTop: 10 }}
        >
          <Button onClick={handleManualAnalyze} disabled={loading}>
            Analyze
          </Button>

          <Typography style={{ paddingLeft: 16 }}>
            AIA events: {loading ? 'Analysis...' : countLabel}
          </Typography>
        </Grid>

        <Grid item style={{ display: 'flex', gap: 16 }}>
          <EventsNav
            setCenter={setCenter}
            allEvents={aiEvents}
            visibleRange={visibleRange}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
            selectedBeatRange={selectedBeatRange}
            setSelectedBeatRange={setSelectedBeatRange}
          />

          <Button onClick={onClose}>Close</Button>
        </Grid>
      </Grid>
    </Card>
  );
};

AnalyzeModal.propTypes = {
  center: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  resource: PropTypes.string.isRequired,
};

export default AnalyzeModal;
