import { createEnum } from 'common/utils/actionTypesHelper';
import { abnormalityTypeEnum } from 'common/constants/ecgEnums';

import veNormalizers from './veNormalizers';

const defaultECGNormalizer = (ecg = []) => ecg;

export const ecgNormalizers = createEnum({
  [abnormalityTypeEnum.ve]: veNormalizers,
  [abnormalityTypeEnum.sve]: veNormalizers,
  [abnormalityTypeEnum.beats]: veNormalizers,

  default: defaultECGNormalizer,
});
