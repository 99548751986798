import { InferenceSession, Tensor, env } from 'onnxruntime-web';

import { modelsAccordingEventType } from '../constants/models';
import { wasmPaths, aiModelTypes } from '../constants/enums';
import { ecgNormalizers } from './normalizers';

env.wasm.wasmPaths = wasmPaths;

const getModelPath = (abType, modelType) =>
  modelsAccordingEventType[abType][modelType];

const getTensorData = (
  normalizedEcg /* abType, modelType = aiModelTypes.AI */
) => {
  // const normalizer = ecgNormalizers[abType][modelType];
  //
  // const normalizedEcg = normalizer(ecg);
  const dims = [1, normalizedEcg.length, 1];
  const data = new Float32Array(normalizedEcg).map(Number);
  return new Tensor(data, dims);
};

export const aiEventAnalyzer = async ({ ecg, abnormalityType }) => {
  try {
    const modelPath = (await getModelPath(abnormalityType, aiModelTypes.AI))
      .default;

    const normalizer = ecgNormalizers[abnormalityType][aiModelTypes.AI];

    const normalizedEcg = normalizer(ecg);

    const session = await InferenceSession.create(modelPath);
    const tensor = getTensorData(normalizedEcg, abnormalityType);
    const feeds = { input_input: tensor };

    const res = await session.run(feeds);

    return res.output;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

const resKey = 'time_distributed';

export const aiaEventAnalyzer = async ({
  ecg,
  abnormalityType,
  acceptanceThreshold,
  originalEcg,
  normalizeOptions,
}) => {
  try {
    const modelPath = (await getModelPath(abnormalityType, aiModelTypes.AIA))
      .default;

    const session = await InferenceSession.create(modelPath);
    const normalizer = ecgNormalizers[abnormalityType][aiModelTypes.AI];

    const normalizedEcg = normalizer(
      ecg,
      normalizeOptions.lowerExtreme,
      normalizeOptions.upperExtreme
    );

    const tensor = getTensorData(
      normalizedEcg,
      abnormalityType,
      aiModelTypes.AIA
    );
    const feeds = { input_ecg: tensor };

    const res = await session.run(feeds);

    // eslint-disable-next-line no-console
    console.log({
      abnormalityType,
      inputECG: ecg,
      result: res,
      originalEcg,
      normalizedEcg,
      acceptanceThreshold,
      normalizeOptions,
    });

    const resultKeyName = Object.keys(res).find((k) => k.startsWith(resKey));

    return res[resultKeyName];
  } catch (e) {
    console.error(e);
    return undefined;
  }
};
