import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import FlipIcon from '@material-ui/icons/FlipCameraAndroidOutlined';

import {
  ecgTypeLabels,
  classifyOptions,
  abnormalityTypeEnum,
} from 'common/constants/ecgEnums';
import {
  closeModal,
  openFullDisclosureModal,
} from 'common/ducks/general/actions/modalActions';
import paths from 'common/constants/path';
import { useProcedureLocked } from 'common/hooks';
import useRouterInfo from 'common/hooks/useRouterInfo';
import { eventsActions } from 'common/ducks/general/actions';
import escalatedReport from 'common/modules/escalatedReport';
import { useNotify } from 'common/modules/Notification/hooks';
import { symptomType } from 'common/constants/sharedPropTypes';
import Button from 'common/components/buttons/ModalActionButton';
import TextInput from 'common/components/simpleInputs/TextInput';
import useAuthProvider from 'store/authProvider/useAuthProvider';
import { clearUndoState } from 'common/modules/Undo/ducks';
import { modalTypes, ecgEventStatus } from 'common/constants/enums';
import AiAnalyzeButton from 'common/modules/AiAnalyzer';

import { getPatientEventById } from '../ducks';
import PrevNextEventsNavigation from './PrevNextEventsNavigation';
import TooltipForHRTypeEvents from './TooltipForHRTypeEvents';

const { useEscalatedReport } = escalatedReport.hooks;

const useStyles = makeStyles(({ spacing }) => ({
  wrapper: {
    padding: spacing(1, 2),
  },
  classifySelect: {
    width: '35%',
  },
  reportButton: {
    width: 138,
    height: 32,
  },
}));

const optionsWithNoise = [
  ...classifyOptions,
  {
    value: abnormalityTypeEnum.noiseArtifact,
    label: ecgTypeLabels[abnormalityTypeEnum.noiseArtifact],
  },
];

const EventStripButtons = ({
  center,
  onClose,
  statuses,
  resource,
  disabled,
  isNoBeats,
  toggleRuler,
  procedureId,
  openedEvent,
  isModalView,
  selectedEvent,
  physicianView,
  currentEventId,
  isCurrentEventPE,
  selectedBeatRange,
  selectedPatientEvent,
  setSelectedBeatRange,
}) => {
  const router = useRouterInfo();
  const styles = useStyles();
  const notify = useNotify();
  const dispatch = useDispatch();
  const { isEcgTech } = useAuthProvider();
  const [classification, setClassification] = useState(null);

  const { loading, report, create, addEvent, addPatientEvent } =
    useEscalatedReport(procedureId);

  const locked = useProcedureLocked();

  const buttonsDisabled = useMemo(() => locked || disabled, [locked, disabled]);

  const isMonitoringPage = useMemo(
    () => router?.url === paths.private.procedures.monitoring.index,
    [router]
  );

  const modifiedClassifyOptions = useMemo(() => {
    if (selectedPatientEvent) {
      return optionsWithNoise;
    }

    return classifyOptions;
  }, [selectedPatientEvent]);

  const eventId = useMemo(() => selectedEvent?.id, [selectedEvent]);

  const updateEvent = useCallback(
    (status, onSuccess = null) => {
      const {
        id,
        note,
        abnormalityType = abnormalityTypeEnum.NSR,
      } = selectedEvent || {};
      const ev = {
        note,
        status,
        abnormalityType: classification || abnormalityType,
      };

      if (id || eventId) {
        ev.id = id || eventId;
      } else {
        const { date } = selectedPatientEvent || {};

        const [dateFrom, dateTo] = selectedBeatRange || [
          moment(date).subtract(500, 'milliseconds'),
          moment(date).add(500, 'milliseconds'),
        ];
        ev.dateFrom = dateFrom && dateFrom.toISOString();
        ev.dateTo = dateFrom && dateTo.toISOString();
        ev.procedureId = procedureId;
      }

      const handleSuccess = (...args) => {
        if (onSuccess) {
          onSuccess(...args);

          return;
        }

        notify.success({
          message: 'Events has been updated successfully',
          undoable: true,
        });
      };

      dispatch(
        eventsActions.updateEcgEvents(
          [ev],
          [selectedEvent || ev],
          handleSuccess
        )
      );
    },
    [
      notify,
      eventId,
      dispatch,
      procedureId,
      selectedEvent,
      classification,
      selectedBeatRange,
      selectedPatientEvent,
    ]
  );

  const updatePatientEvent = useCallback(
    (status, onSuccess = null) => {
      const { ecgEventGroupIds } = selectedPatientEvent;
      const isEcgEventsAttached = !!ecgEventGroupIds?.length;
      const newPatientEvent = { ...selectedPatientEvent, status };
      const isDenied = status === ecgEventStatus.denied;

      const payload = {
        query: { procedureId },
        body: newPatientEvent,
      };

      // update PE status and add current selected exg event
      if (!isEcgEventsAttached) {
        const handleUpdatePeOnNSRCreate = (response) => {
          const { data } = response.payload;
          newPatientEvent.ecgEventGroupIds = [data?.[0]?.id];
          payload.body = newPatientEvent;

          dispatch(
            eventsActions.updatePatientEvent(
              payload,
              [{ ...selectedPatientEvent, procedureId }],
              onSuccess
            )
          );
        };

        updateEvent(
          isDenied ? status : ecgEventStatus.accepted,
          handleUpdatePeOnNSRCreate
        );

        return;
      }

      if (!selectedEvent) {
        const handleSuccess = ({ payload: { data } }) => {
          newPatientEvent.ecgEventGroupIds = [
            ...newPatientEvent.ecgEventGroupIds,
            data?.[0]?.id,
          ].filter(Boolean);

          payload.body = newPatientEvent;

          dispatch(
            eventsActions.updatePatientEvent(payload, [
              { ...selectedPatientEvent, procedureId },
              onSuccess,
            ])
          );
        };

        if (isDenied) {
          dispatch(
            eventsActions.bulkStatusUpdate(
              newPatientEvent.ecgEventGroupIds.map((id) => ({ id, status })),
              handleSuccess
            )
          );
        } else {
          updateEvent(ecgEventStatus.accepted, handleSuccess);
        }

        return;
      }

      // update PE status and attached ecg events
      const isSelectedEcgAttached = newPatientEvent.ecgEventGroupIds.find(
        (ecgEventId) => ecgEventId === selectedEvent?.id
      );

      newPatientEvent.ecgEventGroupIds =
        selectedEvent && !isSelectedEcgAttached
          ? [...newPatientEvent.ecgEventGroupIds, selectedEvent?.id].filter(
              Boolean
            )
          : newPatientEvent.ecgEventGroupIds;

      payload.body = newPatientEvent;

      const handleSuccess = () => {
        if (isDenied) {
          dispatch(
            eventsActions.bulkStatusUpdate(
              newPatientEvent.ecgEventGroupIds.map((id) => ({ id, status }))
            )
          );
        } else {
          updateEvent(ecgEventStatus.accepted);
        }

        if (onSuccess) {
          onSuccess();
        }
      };

      dispatch(
        eventsActions.updatePatientEvent(
          payload,
          [{ ...selectedPatientEvent, procedureId }],
          handleSuccess
        )
      );
    },
    [dispatch, procedureId, updateEvent, selectedEvent, selectedPatientEvent]
  );

  const handleReview = useCallback(
    (status) => {
      // clear undo state before new action to avoid conflicts
      dispatch(clearUndoState());

      const onSuccess = () =>
        notify.success({
          message: 'Events has been updated successfully',
          undoable: true,
        });

      if (selectedPatientEvent) {
        updatePatientEvent(status, onSuccess);
      } else {
        updateEvent(status, onSuccess);
      }

      if (onClose) {
        onClose();
      }
    },
    [
      notify,
      onClose,
      dispatch,
      updateEvent,
      updatePatientEvent,
      selectedPatientEvent,
    ]
  );

  const isDisabledReportButton = useMemo(() => {
    if (selectedPatientEvent) {
      return Boolean(
        !selectedPatientEvent?.status && !selectedBeatRange && !eventId
      );
    }

    if (!eventId && report) {
      return true;
    }

    return locked || (disabled && !!report);
  }, [
    report,
    locked,
    eventId,
    disabled,
    selectedBeatRange,
    selectedPatientEvent,
  ]);

  const handleClose = () => dispatch(closeModal(modalTypes.eventStripModal));

  const addPEToReport = () => addPatientEvent(selectedPatientEvent.id);

  const handleReportButton = async () => {
    // accept pe -> create report -> add pe to report
    if (!report && selectedPatientEvent && !selectedPatientEvent?.status) {
      const successReviewPE = () => create(eventId, selectedPatientEvent);

      updatePatientEvent(ecgEventStatus.accepted, successReviewPE);

      return;
    }

    // create report -> add event to report
    if (!report) {
      create(eventId, selectedPatientEvent);
      return;
    }

    if (!eventId && !selectedPatientEvent?.id) {
      notify.error(
        'Please, select event or patient event for adding to report'
      );

      return;
    }

    if (eventId && !selectedPatientEvent) {
      addEvent(eventId);
      return;
    }

    if (selectedPatientEvent && !selectedPatientEvent?.status) {
      const onPeGetSuccess = ({ payload: { data: pe } }) => {
        if (pe && pe.status) {
          addPEToReport();

          return;
        }

        updatePatientEvent(ecgEventStatus.accepted, addPEToReport);
      };

      const onPeGetError = () => {
        notify.error('Patient event not fount or outdated');
      };

      // check is pe updated before adding new NSR
      dispatch(
        getPatientEventById(
          { id: selectedPatientEvent.id },
          statuses,
          resource,
          onPeGetSuccess,
          onPeGetError
        )
      );
      return;
    }

    addPEToReport();
  };

  const toggleInvert = () => {
    if (isCurrentEventPE) {
      dispatch(
        eventsActions.setInvertPEEcg(
          { id: currentEventId },
          undefined,
          undefined,
          false
        )
      );
      return;
    }

    dispatch(
      eventsActions.setInvertEcg(
        {
          ids: [currentEventId],
          procedureId,
        },
        undefined,
        undefined,
        false
      )
    );
  };

  const reClassifyHandler = (e, v) => {
    setClassification(v);
  };

  const onClickFullDisclosure = () =>
    dispatch(openFullDisclosureModal({ selectedTime: center }));

  useEffect(() => {
    setClassification(selectedEvent?.abnormalityType);
  }, [selectedEvent?.abnormalityType]);

  return (
    <Grid
      container
      spacing={1}
      wrap="nowrap"
      alignItems="center"
      className={styles.wrapper}
    >
      {!physicianView && (
        <>
          <Grid item>
            <Button
              disabled={buttonsDisabled}
              onClick={() => handleReview(ecgEventStatus.accepted)}
            >
              Accept
            </Button>
          </Grid>

          <Grid item>
            <Button
              disabled={buttonsDisabled}
              onClick={() => handleReview(ecgEventStatus.included)}
            >
              Include
            </Button>
          </Grid>

          {!isEcgTech && (
            <Grid item>
              <Button
                disabled={loading || isDisabledReportButton}
                onClick={handleReportButton}
                className={styles.reportButton}
              >
                {loading && <CircularProgress size={22} />}
                {!loading && report && 'Add Event'}
                {!loading && !report && 'Create Report'}
              </Button>
            </Grid>
          )}

          <Grid item className={styles.classifySelect}>
            <TextInput
              select
              fullWidth
              size="small"
              label="Classify"
              name="type-select"
              defaultValue=""
              disabled={buttonsDisabled}
              value={
                (classification !== abnormalityTypeEnum.NSR &&
                  classification) ||
                ''
              }
              options={modifiedClassifyOptions}
              onChange={reClassifyHandler}
            />
          </Grid>

          {!isMonitoringPage && (
            <Grid item>
              <TooltipForHRTypeEvents event={openedEvent}>
                <Button
                  disabled={buttonsDisabled || !currentEventId}
                  onClick={toggleInvert}
                  endIcon={<FlipIcon />}
                >
                  Invert
                </Button>
              </TooltipForHRTypeEvents>
            </Grid>
          )}

          <Grid item>
            <Button
              disabled={buttonsDisabled}
              onClick={() => handleReview(ecgEventStatus.denied)}
            >
              Deny
            </Button>
          </Grid>
        </>
      )}

      {physicianView && (
        <Grid item>
          <Button onClick={onClickFullDisclosure}>View Full Disclosure</Button>
        </Grid>
      )}

      <Grid item>
        <Button disabled={buttonsDisabled || isNoBeats} onClick={toggleRuler}>
          Measure
        </Button>
      </Grid>

      {isModalView && (
        <Grid item>
          <Button onClick={handleClose}>Close/Exit</Button>
        </Grid>
      )}

      <PrevNextEventsNavigation
        center={center}
        resource={resource}
        isModal={isModalView}
        selectedEvent={selectedEvent}
        selectedBeatRange={selectedBeatRange}
        setSelectedBeatRange={setSelectedBeatRange}
        selectedPatientEvent={selectedPatientEvent}
      />

      {isMonitoringPage && (
        <AiAnalyzeButton event={selectedEvent} resource={resource} />
      )}
    </Grid>
  );
};

EventStripButtons.defaultProps = {
  statuses: null,
  disabled: false,
  isNoBeats: false,
  isModalView: false,
  physicianView: false,
  currentEventId: null,
  onClose: () => null,
  toggleRuler: () => null,
};

EventStripButtons.propTypes = {
  onClose: PropTypes.func,
  disabled: PropTypes.bool,
  center: PropTypes.string,
  isNoBeats: PropTypes.bool,
  resource: PropTypes.string,
  isModalView: PropTypes.bool,
  toggleRuler: PropTypes.func,
  openedEvent: PropTypes.objectOf(PropTypes.any),
  physicianView: PropTypes.bool,
  procedureId: PropTypes.string,
  currentEventId: PropTypes.string,
  isCurrentEventPE: PropTypes.bool,
  selectedPatientEvent: symptomType,
  setSelectedBeatRange: PropTypes.func,
  selectedEvent: PropTypes.objectOf(PropTypes.any),
  selectedBeatRange: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
  ),
  statuses: PropTypes.arrayOf(PropTypes.string),
};

export default EventStripButtons;
