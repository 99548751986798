import React, {
  memo,
  useRef,
  useMemo,
  useEffect,
  useCallback,
  useState,
} from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import Increase from '@material-ui/icons/Add';
import Decrease from '@material-ui/icons/Remove';

import {
  focusProps,
  ecgStripType,
  settingProps,
  containerOptions,
} from 'common/constants/sharedPropTypes';
import UID from 'common/utils/helpers/UID';
import Grid from '@material-ui/core/Grid';
import { xGen, marginPaddingWrapper, yGen } from '../utils';

const outsideContainerIds = ['symptom', 'y-axis', 'x-axis'];

const ZoomButton = ({ onClick, isIncrease }) => {
  const Component = isIncrease ? Increase : Decrease;

  return (
    <Grid
      item
      style={{ backgroundColor: '#ffffff', cursor: 'pointer' }}
      onClick={onClick}
    >
      <Component />
    </Grid>
  );
};

const D3Container = ({
  children,
  scroll,
  data,
  width,
  height,
  options,
  focus,
  onClick,
  zoomable,
  foreignObjects,
  rectangleStrokeWidth = 0.5,
  onScrollHandler = () => null,
  forceScroll = false,
  stripSettings: settings = { speed: 1, amplitude: 1 },
}) => {
  const clip = useMemo(() => new UID('clip-id'), []);
  const { xPropKey = 'i', yPropKey = 'y' } = options;
  const margin = marginPaddingWrapper(options.margin);
  const padding = marginPaddingWrapper(options.padding, true);
  const {
    center = new Date(),
    duration = 6,
    domain = { x: null, y: null },
  } = focus;

  const [ref, setRef] = useState(null);
  const updatedCenter = useRef(new Date(center));
  const [scale, setStateScale] = useState({ current: null });
  const [yZoomLevel, setYZoomLevel] = useState(1);

  const scaleLevel = useMemo(() => {
    const [first, last] = domain.x || d3.extent(data, (d) => d[xPropKey]);
    // difference between start and end of procedure data (only first part fo data) in seconds
    const diff = (new Date(last) - new Date(first)) / 1000;
    return diff / duration;
  }, [domain.x, data, xPropKey, duration]);

  const xScale = useMemo(
    () => xGen(domain.x || data, xPropKey, [margin.left, width - margin.right]),
    [domain.x, data, margin, width, xPropKey]
  );

  const yScale = useMemo(
    () =>
      yGen(
        domain.y || data,
        yPropKey,
        [height - margin.bottom, margin.top],
        yZoomLevel
      ),
    [domain.y, data, yPropKey, height, margin.bottom, margin.top, yZoomLevel]
  );

  const startPoint = useMemo(
    () => (options.margin?.left || 0) + (options.margin?.right || 0),
    [options.margin]
  );

  const zoom = useMemo(
    () =>
      d3
        .zoom()
        .scaleExtent([0.1, 360])
        .extent([
          [margin.left, 0],
          [width - margin.right, height],
        ])
        .translateExtent([
          [margin.left, -Infinity],
          [width - margin.right, Infinity],
        ]),
    [height, margin.left, margin.right, width]
  );

  const zoomY = useMemo(() => d3.zoom().scaleExtent([0.1, 360]), []);

  const svg = useMemo(
    () =>
      d3
        .select(ref)
        .call(zoom)
        .call(zoom.scaleTo, scaleLevel, [xScale(updatedCenter.current), 0])
        .on('dblclick.zoom', null)
        .on('wheel.zoom', (event) => {
          if (!event.shiftKey || !scroll) {
            return event;
          }

          event.preventDefault();
          svg.call(
            zoom.translateBy,
            (-1 * event.deltaX) / 10,
            event.deltaY / 10
          );
          return null;
        }),
    [ref, scaleLevel, xScale, zoom, scroll]
  );

  const getIsClickInsideGraph = useCallback(
    (evt) => {
      const { x, y } = d3.select(`#${clip.id} rect`).node().getBBox();
      const [mouseX, mouseY] = d3.pointer(evt);

      return mouseX > x && mouseY > y;
    },
    [clip]
  );

  const clickHandler = useCallback(
    (ev) => {
      const isInside = getIsClickInsideGraph(ev);

      if (outsideContainerIds.includes(ev.target.id) || !isInside) {
        return;
      }

      const [d3point] = d3.pointer(ev);
      const point = scale?.current
        ? scale?.current.invert(d3point)
        : xScale.invert(d3point);

      onClick(point, ev.shiftKey, ev);
    },
    [getIsClickInsideGraph, scale, xScale, onClick]
  );

  const onZoom = useCallback(
    (event) => {
      const xz = event.transform.rescaleX(xScale);
      updatedCenter.current = xz.invert(width / 2);
      setStateScale({ current: xz });

      const startVisible = xz.invert(startPoint);
      const endVisible = xz.invert(width);

      const isInvalid = [startVisible, endVisible].some(
        (d) => !(d instanceof Date) || Number.isNaN(+d)
      );

      if (isInvalid) {
        return;
      }

      const range = [startVisible.toISOString(), endVisible.toISOString()];

      onScrollHandler(xz.invert, xz, range);
    },
    [onScrollHandler, startPoint, width, xScale]
  );

  const onZoomY = useCallback(
    (event) => {
      const yz = event.transform.rescaleY(xScale);

      yScale.domain(yz.domain());
    },
    [xScale, yScale]
  );

  useEffect(() => {
    if (zoomable && zoomY) {
      zoomY.on('zoom', onZoomY);
    }

    if (scroll && zoom) {
      zoom.on('zoom', onZoom);
    }

    return () => scroll && zoom && zoom.on('zoom', null);
  }, [onZoom, onZoomY, scroll, zoom, zoomY, zoomable]);

  useEffect(() => {
    if (svg) {
      svg.on('click', clickHandler);
    }

    return () => svg.on('click', null);
  }, [svg, clickHandler]);

  useEffect(() => {
    if (!forceScroll || !svg || !center) return;

    updatedCenter.current = new Date(center);
    svg.call(zoom.translateTo, xScale(new Date(center)), 0);
  }, [forceScroll, center, svg, zoom.translateTo, xScale]);

  const childProps = {
    svg,
    data,
    zoom,
    clip,
    width,
    height,
    domain,
    center: updatedCenter.current,
    duration,
    xScale,
    yScale,
    scale: scale.current,
    options: {
      margin,
      padding,
      xPropKey,
      yPropKey,
      settings,
    },
  };

  const childrenPropsMapper = (child) => {
    if (!child) return child;

    return React.cloneElement(child, {
      parent: childProps,
    });
  };

  const childrenWithProps = React.Children.map(children, childrenPropsMapper);
  const foreignObjectsWithProps = React.Children.map(
    foreignObjects,
    childrenPropsMapper
  );

  const rectWidth = Math.max(Math.abs(width - margin.left - margin.right), 0);
  const rectHeight = Math.max(Math.abs(height - margin.top - margin.bottom), 0);

  const zoomIn = (v) => () => {
    if (v) {
      setYZoomLevel((l) => l + 0.1);
      return;
    }
    zoom.scaleBy(svg, 1.3);
  };

  const zoomOut = (v) => () => {
    if (v) {
      setYZoomLevel((l) => Math.max(l - 0.1, 0.1));
      return;
    }
    zoom.scaleBy(svg, 1 / 1.3);
  };

  return (
    <>
      {zoomable && (
        <Grid
          style={{
            top: 1,
            left: 17,
            bottom: 1,
            display: 'flex',
            position: 'absolute',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Grid container direction="column" style={{ maxWidth: 26 }}>
            <ZoomButton isIncrease onClick={zoomIn(true)} />
            <ZoomButton onClick={zoomOut(true)} />
          </Grid>

          <Grid container>
            <ZoomButton onClick={zoomOut()} />
            <ZoomButton isIncrease onClick={zoomIn()} />
          </Grid>
        </Grid>
      )}
      {foreignObjectsWithProps}
      <svg
        ref={setRef}
        width={width}
        height={height}
        viewBox={`0, 0, ${width || 0}, ${height || 0}`}
      >
        <clipPath id={clip.id}>
          <rect
            y={margin.top}
            x={margin.left}
            width={rectWidth - 0.5}
            height={rectHeight}
          />
        </clipPath>

        {childrenWithProps}

        <rect
          fill="none"
          stroke="black"
          y={margin.top}
          x={margin.left}
          width={rectWidth - 0.5}
          height={rectHeight}
          strokeWidth={rectangleStrokeWidth}
        />
      </svg>
    </>
  );
};

D3Container.defaultProps = {
  width: 0,
  height: 0,
  focus: {},
  options: {},
  scroll: true,
  zoomable: false,
  foreignObjects: [],
  onClick: () => null,
};

D3Container.propTypes = {
  data: PropTypes.arrayOf(ecgStripType),
  foreignObjects: PropTypes.arrayOf(PropTypes.any),
  focus: focusProps,
  scroll: PropTypes.bool,
  zoomable: PropTypes.bool,
  forceScroll: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.node,
  options: containerOptions,
  stripSettings: settingProps,
  onClick: PropTypes.func,
  onScrollHandler: PropTypes.func,
  rectangleStrokeWidth: PropTypes.number,
};

const isShouldBeMemoized = (prev, next) => {
  const {
    data: prevData,
    children: prevChildren,
    foreignObjects: prevFO,
    ...prevProps
  } = prev;
  const {
    data: nextData,
    children: nextChildren,
    foreignObjects: nextFO,
    ...nextProps
  } = next;

  const prevValidChildren = (
    Array.isArray(prevChildren) ? prevChildren : [prevChildren]
  )
    .concat(prevFO)
    .filter(Boolean)
    .flat();

  const nextValidChildren = (
    Array.isArray(nextChildren) ? nextChildren : [nextChildren]
  )
    .concat(nextFO)
    .filter(Boolean)
    .flat();

  return (
    isEqual(prevProps, nextProps) &&
    prevData?.length === nextData?.length &&
    prevValidChildren.length === nextValidChildren.length &&
    nextValidChildren.every(({ props }, i) =>
      isEqual(props, prevValidChildren[i].props)
    )
  );
};

export default memo(D3Container, isShouldBeMemoized);
