import React, { useCallback, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { v4 as uuidv4 } from 'uuid';

import Button from 'common/components/buttons/ModalActionButton';
import { abnormalityTypeEnum } from 'common/constants/ecgEnums';
import { getCenterBetweenDatesUTC } from 'common/utils/helpers/date';

import { findIndexPrevOrNextEvent } from '../utils/getters';

const EventsNavigation = ({
  allEvents,
  setCenter,
  visibleRange,
  selectedEvent = null,
  setSelectedEvent,
  selectedBeatRange = null,
  setSelectedBeatRange = () => null,
}) => {
  const time = useMemo(
    () =>
      selectedEvent?.dateFrom ||
      selectedBeatRange?.[0] ||
      getCenterBetweenDatesUTC(...(visibleRange || [])),
    [selectedBeatRange, selectedEvent?.dateFrom, visibleRange]
  );

  const { prevEvent, nextEvent } = useMemo(
    () => findIndexPrevOrNextEvent(time, allEvents || []),
    [allEvents, time]
  );

  const prevDisabled = useMemo(() => !prevEvent, [prevEvent]);
  const nextDisabled = useMemo(() => !nextEvent, [nextEvent]);

  const setNewSelectedEvent = useCallback(
    (e) => {
      const event = { ...e, uniqueKey: uuidv4() };

      setSelectedEvent(event);

      if (event.abnormalityType === abnormalityTypeEnum.patient) {
        setSelectedBeatRange(null);
      }

      setCenter(e.dateFrom);
      setSelectedBeatRange([e.dateFrom, e.dateTo].map((d) => new Date(d)));
    },
    [setCenter, setSelectedEvent, setSelectedBeatRange]
  );

  const handlePrev = useCallback(() => {
    if (!prevEvent) {
      return;
    }

    setNewSelectedEvent(prevEvent);
  }, [setNewSelectedEvent, prevEvent]);

  const handleNext = useCallback(() => {
    setNewSelectedEvent(nextEvent);
  }, [setNewSelectedEvent, nextEvent]);

  return (
    <>
      <Grid item>
        <Button
          disabled={prevDisabled}
          onClick={handlePrev}
          icon={<ChevronLeftIcon />}
        />
      </Grid>

      <Grid item>
        <Button
          disabled={nextDisabled}
          onClick={handleNext}
          icon={<ChevronRightIcon />}
        />
      </Grid>
    </>
  );
};

EventsNavigation.propTypes = {
  allEvents: PropTypes.arrayOf(PropTypes.shape()),
  setCenter: PropTypes.func,
  visibleRange: PropTypes.arrayOf(PropTypes.any),
  selectedEvent: PropTypes.shape(),
  setSelectedEvent: PropTypes.func,
  setSelectedBeatRange: PropTypes.func,
  selectedBeatRange: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
  ),
};

export default EventsNavigation;
