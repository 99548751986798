import React, { useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'common/components/buttons/ModalActionButton';
import { openAiAnalyze } from 'common/ducks/general/actions/modalActions';

import { getLoading, getVisibleRange } from '../eventStrip/ducks/selectors';
import { getCenterBetweenDatesUTC } from '../../utils/helpers/date';

const AiAnalyzeButton = ({ event, resource }) => {
  const dispatch = useDispatch();

  const loading = useSelector(getLoading(resource));
  const range = useSelector(getVisibleRange(resource));

  const center = useMemo(
    () =>
      event?.dateFrom ||
      (range ? getCenterBetweenDatesUTC(...range).toISOString() : ''),
    [range, event]
  );

  return (
    <Grid item>
      <Button
        disabled={loading}
        onClick={() => dispatch(openAiAnalyze({ resource, center }))}
      >
        AI Analyze
      </Button>
    </Grid>
  );
};

AiAnalyzeButton.propTypes = {
  resource: PropTypes.string.isRequired,
  event: PropTypes.shape({
    dateFrom: PropTypes.string.isRequired,
    procedureId: PropTypes.string.isRequired,
    abnormalityType: PropTypes.string.isRequired,
  }),
};

export default AiAnalyzeButton;
